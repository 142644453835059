import { useCallback } from "react";
import { getToken } from "firebase/messaging";
import mixpanel from "mixpanel-browser";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { addFirebaseToken, verifyToken } from "../api/api";
import Hello from "../Component/Layout/Hello";
import Layout from "../Component/Layout/Layout";
import { messaging } from "../firebaseSetup";
import IndexModal from "../modals/IndexModal";
import Concerts from "../pages/Concerts";
import Festivals from "../pages/Festivals";
import Searchempty from "../pages/searchempty";
import LoginPage from "../pages/Login";
import { openUpdateProfile } from "../redux/Reducers/modalReducer";
import { logout, setUser, setUserLogin } from "../redux/Reducers/userReducer";
import EnsureAccountVerification from "../Routes/EnsureAccountVerification";
import NotFound from "./NotFound";
import Protected from "./Protected";
import PublicRoutes from "./PublicRoutes";
import Chat from "../Component/Chat/Chat";
import { getPostOnboardingAction } from "../utils/postOnboardingAction";
import { useNavigate } from "react-router-dom";

// import IPInfo from "ip-info-react";

// Use lazy for component imports
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Event = lazy(() => import("../pages/Event"));
const Fillter = lazy(() => import("../Component/fillter/fillter"));
const Myevent = lazy(() => import("../pages/Myevent"));
const Requestsuccessfullysubmitted = lazy(() =>
  import(
    "../Component/requestsuccessfullysubmitted/requestsuccessfullysubmitted"
  )
);
const Profile = lazy(() => import("../pages/Profile.tsx"));
const Yourfeedback = lazy(() => import("../modals/yourfeedback/yourfeedback"));
const Jointribe = lazy(() => import("../pages/Jointribe"));
const Tribewill = lazy(() => import("../Component/Tribewill/tribewill"));
const Suggestionsfeatures = lazy(() => import("../pages/suggestionsfeatures"));
const Termscondition = lazy(() => import("../pages/termscondition"));
const Firstmessage = lazy(() => import("../pages/firstmessage"));
const Reportuser = lazy(() => import("../modals/reportuser/reportuser"));
const PublicProfile = lazy(() => import("../pages/PublicProfile"));

const AppRoutes = () => {
  const { isUserLogedIn, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      localStorage.setItem("notificationDenied", 0);
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });

        await addFirebaseToken(user._id, token);
      } catch (error) {
        console.log("Error in requesting permission", error);
      }
    }
  }, [user]);

  const handleAuthenticationFlow = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const newToken = searchParams.get("token");
    const existingToken = localStorage.getItem("token");
    const tokenToUse = newToken || existingToken;

    if (newToken) {
      localStorage.setItem("token", newToken);
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, document.title, newUrl);
    }

    if (!tokenToUse) {
      dispatch(logout());
      return;
    }

    try {
      const response = await verifyToken({ token: tokenToUse });
      if (!response.data) {
        dispatch(logout());
        return;
      }

      dispatch(setUser(response.data));
      dispatch(setUserLogin());

      const requiredProperties = [
        "first_name",
        "last_name",
        "gender",
        "email",
        "dob",
      ];
      const missingProperties = [];

      for (const prop of requiredProperties) {
        if (
          !response.data.hasOwnProperty(prop) ||
          response.data[prop] === null ||
          response.data[prop] === undefined
        ) {
          missingProperties.push(prop);
        }
      }

      const properties = {
        isValid: missingProperties.length === 0,
        message:
          missingProperties.length === 0
            ? "All required properties are present."
            : `Missing properties: ${missingProperties.join(", ")}`,
        missingProperties,
      };

      if (!properties.isValid) {
        const name = response.data?.first_name;
        mixpanel.identify(response.data._id);
        mixpanel.people.set({
          $name: name ?? "name not added",
          $email: response.data.email ?? "email not added",
        });
        dispatch(openUpdateProfile());
      }

      if (newToken) {
        const postOnboardingAction = getPostOnboardingAction();
        if (postOnboardingAction?.eventId && postOnboardingAction?.action) {
          const redirectUrl = `/event/${
            postOnboardingAction.eventId
          }?from=auth&action=${postOnboardingAction.action.toLowerCase()}`;
          navigate(redirectUrl);
        }
      }
    } catch (error) {
      console.error("Authentication error:", error);
      dispatch(logout());
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    handleAuthenticationFlow();
  }, [dispatch, handleAuthenticationFlow, navigate]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "token" && e.newValue) {
        handleAuthenticationFlow();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [handleAuthenticationFlow]);

  useEffect(() => {
    if (isUserLogedIn && messaging !== null) {
      requestPermission();
    }
  }, [isUserLogedIn, requestPermission]);

  return (
    <>
      <IndexModal />
      <EnsureAccountVerification user={user} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route path="/test" element={<Hello />} />
          <Route element={<Protected />}>
            <Route path="Profile" element={<Profile />} />
            <Route path="Myevents" element={<Myevent />} />
            <Route exact path="myTribes" element={<Jointribe />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/user/:user_id" element={<PublicProfile />} />
            <Route path="/suggestfeature" element={<Suggestionsfeatures />} />
            <Route path="/festivals" element={<Festivals />} />
          </Route>
          <Route exact path="/Reportuser" element={<Reportuser />} />
          <Route exact path="/Fillter" element={<Fillter />} />
          <Route exact path="/Tribewill" element={<Tribewill />} />
          <Route path="/firstmessage" element={<Firstmessage />} />
          <Route
            path="/Requestsuccessfullysubmitted"
            element={<Requestsuccessfullysubmitted />}
          />
          <Route path="/yourfeedback" element={<Yourfeedback />} />

          <Route path="*" element={<NotFound />} />
          <Route element={<PublicRoutes />}>
            <Route path="/concerts" element={<Concerts />} />
            <Route path="/event/:identifier" element={<Event />} />
            <Route path="/events" element={<Searchempty />} />
            <Route path="/termsconditions" element={<Termscondition />} />
            <Route path="/sign-up" element={<LoginPage />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
