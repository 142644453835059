import BuyTickets from "../../../../Component/TribeChat/buy-tickets/buy-tickets-dropdown";
import { CHAT_HEADER_CLASS } from "../../../../helpers/constants";
import { Users } from "lucide-react";

interface Props {
  tribe: {
    _id: string;
    event_name: string;
    tribe_logo?: string;
    totalJoinees?: number;
    event_id: string;
  };
  onBackClick: () => void;
  onExitChatClick: () => void;
  onToggleMembers: () => void;
  showMembers: boolean;
}

function ChatHeader(props: Props) {
  const { tribe, onBackClick, onToggleMembers, showMembers } = props;

  return (
    <div
      id={CHAT_HEADER_CLASS}
      className="fixed md:static h-20 lg:h-auto top-0 w-full left-0 z-50 text-white backdrop-blur-lg bg-primary-900 lg:bg-transparent bg-opacity-50 lg:px-4"
    >
      <div className="flex items-center items-between gap-2 w-full h-full px-2 lg:px-0">
        <div className="flex items-center gap-2 w-full">
          <button
            onClick={onBackClick}
            className="p-2 hover:bg-primary-800 rounded-full lg:hidden"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <div className="flex-shrink-0">
            {tribe.tribe_logo && (
              <img
                src={tribe.tribe_logo}
                alt={tribe.event_name}
                className="w-10 h-10 flex-shrink-0 rounded-full object-cover"
              />
            )}
          </div>
          <div className=" flex flex-col">
            <div>
              <div className="font-semibold text-sm max-w-[100px] md:max-w-[250px] lg:max-w-[300px] xl:max-w-[700px] truncate overflow-hidden">
                {tribe.event_name}
              </div>
            </div>
            <div className="">
              <span className="text-sm tracking-tight text-primary-400">
                {tribe.totalJoinees || 0}{" "}
                {`Member${tribe.totalJoinees === 1 ? "" : "s"}`}
              </span>
            </div>
          </div>
        </div>
        <button
          onClick={onToggleMembers}
          className={`p-2 rounded-lg transition-colors ${
            showMembers
              ? "bg-primary-800 text-primary-100"
              : "text-primary-300 hover:text-primary-200 hover:bg-primary-900"
          }`}
        >
          <Users className="w-5 h-5" />
        </button>

        <div className="w-[15rem]">
          <BuyTickets componentType="button" eventId={tribe.event_id} />
        </div>
      </div>
    </div>
  );
}

export default ChatHeader;
