import { UseQueryResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { Tribe, TribesPaginationResponse } from "../hooks/tribes.schema";
import GradientProfile from "../../shared/GradientProfile";
import Navbar from "../../Layout/Navbar";
import { Button } from "../../shared/Button";

interface Props {
  onChatClick: (id: string) => void;
  tribes: UseQueryResult<TribesPaginationResponse>;
  selectedTribe: Tribe;
}

function ChatList(props: Props) {
  const { onChatClick, tribes, selectedTribe } = props;
  const navigate = useNavigate();

  if (tribes.status === "pending") {
    return <div>Loading...</div>;
  }

  if (tribes.status === "error") {
    return <div>Error</div>;
  }

  return (
    <div className="overflow-y-auto w-full px-4 pb-2 lg:pt-24 fixed lg:static max-h-[calc(100dvh-4.5rem)] border-r border-primary-900 min-h-full">
      <Navbar />
      <div className="text-primary-200 text-sm font-medium mb-4 pt-20 lg:pt-0">
        <div className="flex items-center justify-between">
          <div className="text-lg font-semibold text-primary-200 tracking-tight">
            Your Tribes 🙌
          </div>
          <Button
            onClick={() => navigate("/")}
            variant="outline"
            size="xs"
            className="md:hidden"
          >
            Back to Events
          </Button>
        </div>
      </div>
      <div className="space-y-3">
        {tribes.data?.docs.map((tribe) => {
          const hasUnreadMessages = tribe.unreadMessagesCount > 0;
          return (
            <button
              key={tribe._id}
              onClick={() => onChatClick(tribe._id)}
              className={`w-full rounded-xl overflow-hidden hover:bg-primary-800/30 px-3 py-2 ${
                selectedTribe?._id === tribe._id ? "bg-primary-800/30" : ""
              }`}
            >
              <div className="flex h-full items-center justify-between">
                <div className="flex items-center flex-1 min-w-0">
                  <div className="relative flex-shrink-0">
                    {tribe.tribe_logo && (
                      <div
                        className={`relative ${
                          hasUnreadMessages
                            ? "after:absolute after:inset-0 after:border-2 after:border-utility-pink after:rounded-full"
                            : ""
                        }`}
                      >
                        <img
                          alt={tribe.event_name}
                          className="h-14 w-14 rounded-full object-cover"
                          src={tribe.tribe_logo}
                          loading="lazy"
                        />
                      </div>
                    )}
                  </div>
                  <div className="ml-3 flex-1 min-w-0 flex flex-col gap-2 justify-between min-h-full">
                    <p
                      className={`text-sm line-clamp-1 mb-0 text-left ${
                        hasUnreadMessages ? "font-bold" : "font-medium"
                      } ${
                        selectedTribe?._id === tribe._id
                          ? "text-utility-pink"
                          : "text-primary-100"
                      }`}
                    >
                      {tribe.event_name}
                    </p>
                    <div className="flex items-center gap-2 text-primary-400 text-sm">
                      <div className="flex -space-x-2">
                        {tribe.joinees
                          .flat()
                          .slice(0, 3)
                          .map((joinee) => (
                            <div
                              key={joinee._id}
                              className="w-6 h-6 rounded-full border-primary-900 overflow-hidden"
                            >
                              {!joinee.profile_pic ||
                              joinee.profile_pic.startsWith("public") ? (
                                <GradientProfile
                                  size="xs"
                                  text={joinee.first_name.slice(0, 1)}
                                />
                              ) : (
                                <img
                                  src={joinee.profile_pic}
                                  alt={`${joinee.first_name} ${joinee.last_name}`.trim()}
                                  className="w-full h-full object-cover"
                                  loading="lazy"
                                />
                              )}
                            </div>
                          ))}
                      </div>
                      <span className="text-xs font-medium text-white">
                        {tribe.joinees?.length} Member
                        {tribe.joinees?.length > 1 && "s"}
                      </span>
                    </div>
                  </div>
                </div>
                {hasUnreadMessages && (
                  <div className="ml-4 flex-shrink-0">
                    <span className="bg-utility-pink text-white text-xs font-medium w-6 h-6 rounded-full flex items-center justify-center">
                      {tribe.unreadMessagesCount}
                    </span>
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default ChatList;
