import { useMutation, useQueryClient } from "@tanstack/react-query";
import { exitTribe, deleteTribe } from "../api/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { closeExitTribeConfirmationModal } from "../redux/Reducers/modalReducer";
import { LAST_SELECTED_TRIBE_KEY } from "../helpers/constants.ts";

interface ExitTribeParams {
  tribe_id: string;
  user_id: string;
  memberCount: number;
}

export const useExitTribe = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ tribe_id, user_id, memberCount }: ExitTribeParams) => {
      const exitResponse = await exitTribe({ tribe_id, user_id });

      if (exitResponse.statusCode !== 200) {
        throw new Error(exitResponse.message || "Failed to exit tribe");
      }

      if (memberCount === 1) {
        const deleteResponse = await deleteTribe(tribe_id);
        if (deleteResponse.statusCode !== 200) {
          throw new Error(deleteResponse.message || "Failed to delete tribe");
        }
      }

      return exitResponse;
    },
    onSuccess: async () => {
      dispatch(closeExitTribeConfirmationModal({}));

      localStorage.removeItem(LAST_SELECTED_TRIBE_KEY);

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ["tribes"] }),
        queryClient.invalidateQueries({ queryKey: ["tribeCounts"] }),
      ]);

      await queryClient.refetchQueries({
        queryKey: ["tribes"],
        exact: true,
      });

      toast.success("Successfully exited tribe");

      window.location.reload();
    },
    onError: (error: Error) => {
      toast.error(error.message || "Failed to exit tribe");
    },
  });
};
