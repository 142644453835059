import React from "react";
import ChatList from "./_components/ChatList";
import MessageArea from "./_components/MessageArea/MessageArea";
import TribeInfoSidebar from "./_components/TribeInfoSidebar";
import { useTribes } from "./hooks/useTribes";
import { MessageTribe } from "./hooks/messages.schema";
import { Tribe } from "../../types/tribes.types";
import { LAST_SELECTED_TRIBE_KEY } from "../../helpers/constants.ts";

function Chat() {
  const tribes = useTribes();
  const [showMembers, setShowMembers] = React.useState(false);

  const savedTribeId = localStorage.getItem(LAST_SELECTED_TRIBE_KEY) || "";

  // Check if the saved tribe  in user's tribes
  React.useEffect(() => {
    if (tribes.data && savedTribeId) {
      const tribeExists = tribes.data.docs.some(
        (tribe) => tribe._id === savedTribeId
      );
      if (!tribeExists) {
        localStorage.removeItem(LAST_SELECTED_TRIBE_KEY);
      }
    }
  }, [tribes.data, savedTribeId]);

  const [selectedTribeId, setSelectedTribeId] = React.useState(savedTribeId);

  const updateSelectedTribe = (tribeId: string) => {
    if (tribeId) {
      localStorage.setItem(LAST_SELECTED_TRIBE_KEY, tribeId);
      setSelectedTribeId(tribeId);
    }
  };

  const [isListView, setIsListView] = React.useState(!savedTribeId);

  const handleBack = () => {
    setIsListView(true);
  };

  if (tribes.status === "error") {
    return (
      <div className="">
        <div className="text-primary-100 text-lg">Error</div>
        <div className="text-primary-300">{tribes.error.message}</div>
      </div>
    );
  }

  if (tribes.status === "pending") {
    return <div className="text-white text-lg">Loading</div>;
  }

  if (tribes.data.docs.length === 0) {
    return (
      <div>
        <div className="text-white text-lg">
          You are not a part of any tribe
        </div>
      </div>
    );
  }

  // If we have a selectedTribeId but it's not in the current tribes list,
  // clear it from storage and state
  if (
    selectedTribeId &&
    !tribes.data.docs.some((tribe) => tribe._id === selectedTribeId)
  ) {
    updateSelectedTribe("");
  }

  // Get the selected tribe or default to first one if none selected
  const selectedTribe = selectedTribeId
    ? tribes.data.docs.find((item) => item._id === selectedTribeId)
    : tribes.data.docs[0];

  if (!selectedTribe) return null;

  const messageTribe: MessageTribe = {
    _id: selectedTribe._id,
    name: selectedTribe.name,
    event_id: selectedTribe.event_id,
    event_name: selectedTribe.event_name ?? "",
    event_location: null,
    event_start_date: new Date().toISOString(),
    event_end_date: new Date().toISOString(),
    tribe_logo: selectedTribe.tribe_logo,
    is_deleted: selectedTribe.is_deleted,
    createdAt: selectedTribe.createdAt,
    updatedAt: selectedTribe.updatedAt,
    __v: 0,
    totalJoinees: selectedTribe.joinees?.length ?? 0,
  };

  const tribeForSidebar: Tribe = {
    ...selectedTribe,
    event_name: selectedTribe.event_name ?? "",
    admin_id: selectedTribe.admin_id ?? "",
    joinees: selectedTribe.joinees ?? [],
    unreadMessagesCount: selectedTribe.unreadMessagesCount ?? 0,
    totalMessagesCount: selectedTribe.totalMessagesCount ?? 0,
  };

  return (
    <div className="fixed inset-0 w-full lg:static">
      <div className="relative h-[100dvh] pb-[env(safe-area-inset-bottom)] lg:h-screen w-full overflow-hidden">
        {/* Wrapper for both panels */}
        <div className="flex h-full w-full">
          {/* Chat List Panel */}
          <div
            className={`absolute lg:static w-full h-full min-w-full  lg:min-w-96 lg:w-96 max-w-96 transition-transform duration-300 ease-in-out ${
              !isListView
                ? "lg:translate-x-0 -translate-x-full"
                : "translate-x-0"
            }`}
          >
            <ChatList
              selectedTribe={selectedTribe}
              tribes={tribes}
              onChatClick={(id) => {
                updateSelectedTribe(id);
                setIsListView(false);
              }}
            />
          </div>

          {/* Message Area Panel */}
          <div
            className={`lg:static lg:pt-24 w-full transition-transform duration-300 ease-in-out ${
              !isListView
                ? "translate-x-0"
                : "translate-x-full lg:translate-x-0"
            }`}
          >
            <MessageArea
              onBackClick={handleBack}
              selectedTribe={messageTribe}
              selectedTribeId={selectedTribeId}
              onToggleMembers={() => setShowMembers(!showMembers)}
              showMembers={showMembers}
            />
          </div>

          {/* Members Sidebar */}
          {showMembers && selectedTribeId && (
            <TribeInfoSidebar
              tribe={tribeForSidebar}
              onClose={() => setShowMembers(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Chat;
