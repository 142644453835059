import { Button } from "../../Component/shared/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { useExitTribe } from "../../hooks/useExitTribe";
import type { TribeExitModalData } from "../../types/tribes.types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { LAST_SELECTED_TRIBE_KEY } from "../../helpers/constants.ts";

const ExitTribeConfirmationModal = (props: any) => {
  const { data } = useSelector((state: RootState) => state?.model) as {
    data: TribeExitModalData | null;
  };
  const { mutate: exitTribe } = useExitTribe();

  if (!data) return null;

  const handleExitTribe = async () => {
    try {
      localStorage.removeItem(LAST_SELECTED_TRIBE_KEY);
      exitTribe({
        tribe_id: data.tribe_id,
        user_id: data.user_id,
        memberCount: data.event.totalJoinees ?? 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleJoinNewTribe = async () => {
  //   try {
  //     if (!isActive) {
  //       const response = await exitTribe({
  //         tribe_id: data.tribe_id,
  //         user_id: data.user_id,
  //       });
  //       if (response.statusCode === 200) {
  //         setIsActive(true);
  //         toast.error(response?.message);
  //       }
  //     }

  //     navigate(`/event/${data?.event}`);
  //     console.log(data.event, "id of the event");
  //     dispatch(closeExitTribeConfirmationModal());
  //     dispatch(
  //       openTribe({
  //         eventId: data.event,
  //       })
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_txt">
              <h2 className="text-white font-bold">
                Are you sure you want<br></br> to exit this tribe ?
              </h2>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button
              onClick={handleExitTribe}
              variant="destructive"
              size="lg"
              className="w-full"
            >
              CONFIRM EXIT
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExitTribeConfirmationModal;
